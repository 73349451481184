<template>
  <div class="idea-map">
    <SeeksRelationGraph ref="ideaMapRelationGraph" :options="graphOptions">
    </SeeksRelationGraph>
  </div>
</template>

<script>
import SeeksRelationGraph from "relation-graph";
import dot from "@/assets/ideamap.png";
export default {
  name: "idea-map",
  components: {
    SeeksRelationGraph,
  },
  props: {
    needRefresh: {
      type: Boolean,
      default: false,
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      graphOptions: {
        defaultFocusRootNode: false,
        layouts: [
          {
            label: "自定义节点",
            layoutName: "fixed",
            layoutClassName: "seeks-layout-fixed",
            defaultJunctionPoint: "border",
            defaultNodeShape: 0,
            defaultLineShape: 1,
          },
        ],
        defaultNodeShape: 1,
        defaultLineShape: 2,
        defaultJunctionPoint: "lr",
        defaultNodeBorderWidth: 0,
        defaultLineColor: "#8E8E93",
        defaultNodeColor: "#ffffff",
        defaultNodeFontColor: "#2A2A31",
        allowShowMiniNameFilter: false,
        hideNodeContentByZoom: true,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.obj) {
        this.setdata();
      }
    }, 1000);
  },
  watch: {
    reset(n, o) {
      if (n && this.needRefresh) {
        this.setdata();
      }
    },
  },
  methods: {
    setdata() {
      console.log("this.obj", this.obj);
      var _graphSetting = this.$refs.ideaMapRelationGraph.graphSetting;
      // 手工设置节点的坐标
      const _center = {
        x: _graphSetting.viewSize.width / 2 - _graphSetting.canvasOffset.x,
        y: _graphSetting.viewSize.height / 2 - _graphSetting.canvasOffset.y,
      };

      const nodeWidth = 80;
      const targetNodeWidth = 140;
      const targetNodeOffset = _center.y - 160;
      const spacing = 300;

      let json = {
        rootId: "main_0",
        nodes: [],
        links: [],
      };
      // 课时目标
      let courseTargetNode = {
        name: "目标",
        id: `main_0`,
        innerHTML: this.secionNode({name:'课时目标', appendix:[]}),
        x: 20,
        y: _center.y,
        width: nodeWidth,
        height: 40,
        disableDefaultClickEffect: true,
      };
      json.nodes.push(courseTargetNode);

      if (this.obj.sectionTarget) {
        let targetNode = {
          id: `taget_000`,
          innerHTML: this.targetNode({actionTarget:this.obj.sectionTarget}),
          x: 20,
          y: targetNodeOffset-300,
          width: targetNodeWidth,
          disableDefaultClickEffect: true,
        };
        json.nodes.push(targetNode);

        let targetLink = {
          from: courseTargetNode.id,
          to: targetNode.id,
          isHideArrow: true,
          lineShape: 1,
        };
        json.links.push(targetLink);
      }

      let before = { name: "课前", appendix: [] };
      let actions = [];
      let after = { name: "课后", appendix: [] };
      this.obj.lessonTimeInfo.forEach((el) => {
        if (el.name == "课前") {
          before = el;
        } else if (el.name == "课中") {
          actions = el.actionList;
        } else if (el.name == "课后") {
          after = el;
        }
      });

      // 课前节点
      let beforeNode = {
        id: `main_0_111`,
        innerHTML: this.secionNode(before),
        x: courseTargetNode.x + spacing,
        y: _center.y,
        width: nodeWidth,
        height: 40,
        disableDefaultClickEffect: true,
      };
      json.nodes.push(beforeNode);

      let courseTargetToBeforeNodeLink = {
          from: courseTargetNode.id,
          to:beforeNode.id,
          isHideArrow: true,
          lineShape: 1
      }
      json.links.push(courseTargetToBeforeNodeLink)

      this.addToolNodeAndLinks(json, beforeNode, before.appendix);

      let prev = beforeNode;
      let mainNodeLength = 1;
      let midNodeX = 0;
      // 模块节点
      actions.forEach((item, index) => {
        item.bgColor = item.bgColor || "#6E76FF";
        mainNodeLength += 1;
        let x = prev.x + spacing;
        midNodeX += x;
        let node = {
          id: `main_${item.actionId}`,
          innerHTML: this.secionNode(item),
          x: x,
          y: _center.y,
          width: nodeWidth,
          height: 40,
          disableDefaultClickEffect: true,
        };
        json.nodes.push(node);
        // 目标节点
        let targetNode = {
          id: `taget_${item.actionId}`,
          innerHTML: this.targetNode(item),
          x: x,
          y: targetNodeOffset,
          width: targetNodeWidth,
          disableDefaultClickEffect: true,
          color: item.bgColor,
          expanded: true
        };
        json.nodes.push(targetNode);
        //工具节点
        this.addToolNodeAndLinks(json, targetNode, item.appendix);
        // 模块节点 到 目标节点的连线
        let targetLink = {
          from: node.id,
          to: targetNode.id,
          isHideArrow: true,
          lineShape: 1,
          color: item.bgColor,
          text:'目标'
        };
        json.links.push(targetLink);

        // 模块节点 之间的连线
        let link = {
          from: prev.id,
          to: node.id,
          isHideArrow: true,
          // color: item.bgColor,
          lineWidth: 2,
        };
        json.links.push(link);
        prev = node;
      });

      //课后节点
      let afterNode = {
        id: `main_999`,
        innerHTML: this.secionNode(after),
        x: (mainNodeLength + 1) * spacing + 20,
        y: _center.y,
        width: nodeWidth,
        height: 40,
        disableDefaultClickEffect: true,
      };
      json.nodes.push(afterNode);
      this.addToolNodeAndLinks(json, afterNode, after.appendix);
      let link = { from: prev.id, to: afterNode.id, isHideArrow: true };
      json.links.push(link);

      // 中间节点
      let midNode = {
        id: "mid_0",
        text: "课中",
        x: (afterNode.x - beforeNode.x) / 2 + beforeNode.x,
        y: _center.y + 80,
        width: 100,
        height: 40,
        disableDefaultClickEffect: true,
      };
      let midToSecondNodeLink = {
        to: midNode.id,
        from: beforeNode.id,
        lineShape: 4,
        isHideArrow: true,
        lineWidth: 2,
        color: "#2C2CC1",
      };
      let midToSecondToLastNodeLink = {
        to: midNode.id,
        from: afterNode.id,
        lineShape: 4,
        isHideArrow: true,
        lineWidth: 2,
        color: "#2C2CC1",
      };
      json.nodes.push(midNode);
      json.links.push(midToSecondNodeLink);
      json.links.push(midToSecondToLastNodeLink);

      this.$refs.ideaMapRelationGraph.setJsonData(json, (seeksRGGraph) => {
        // 这些写上当图谱初始化完成后需要执行的代码
        console.log(seeksRGGraph);
      });
    },
    addToolNodeAndLinks(json, targetNode, tools) {
      tools.forEach((toolItem, index) => {
        let toolNode = {
          id: `tool_${targetNode.id}_${toolItem.AppendixId}`,
          text: toolItem.ApxFileAnthorName || toolItem.ApxFileName,
          styleClass: "m-node-text",
          x: targetNode.x + 80,
          y: targetNode.y - 60 - index * 40,
          height: 30,
          width: 150,
          disableDefaultClickEffect: true,
          expanded: false
        };
        json.nodes.push(toolNode);
        var d = String(/.[^.]+$/.exec(toolItem.ApxUrl)).toLocaleLowerCase();
        if (d == "undefined") {
          d = "";
        }
        let toolLink = {
          from: targetNode.id,
          to: toolNode.id,
          isHideArrow: true,
          lineShape: 4,
          showLineLabel: true,
          text: d,
          color: targetNode.color,
        };
        json.links.push(toolLink);
      });
    },
    secionNode(info) {
      return `<div class="idea-map-p-dot">
                    <img src=${dot} />
                    <p>${info.name || info.actionName}</p>
                  </div>`;
    },
    targetNode(item) {
      return `<div class="idea-map-t">
            <p>${item.actionTarget}</p>
            </div>`;
    },
  },
};
</script>

<style lang="less">
.m-node-text {
    justify-content: flex-start;
    .c-node-text {
        justify-content: flex-start;
    }
}
.clearcolor {
    background: rgba(0, 0, 0, 0);
}
.idea-map-p-dot {
    position: relative;
    p {
        text-align: center;
        font-size: 16Px;
        padding-top: 12Px;
        font-weight: bold;
        color: #2A2A31;
        max-height: 200Px;
        overflow: hidden;
    }
}
.idea-map-t {
    p {
        margin: 0;
        text-align: center;
        font-size: 14Px;
        padding: 4Px 10Px;
    }
}
.idea-map {
    background: #EDF0F5;
    width: 100%;
    height: 400Px;
}
</style>